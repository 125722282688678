/*  eslint-disable */
import React from "react"
import { Link } from "gatsby"

import Header from "src/componentsV2/sections/Header"
import config from "src/config"

import {
  Layout,
  BlueLayout,
  Container,
  Wrapper,
  WrapperMD3,
  LeftWrapper,
  SmallLeftWrapper,
  WrapperSup,
  LargeLeftWrapper,
  RightWrapper,
  Imglogo,
  LargeRightWrapper,
  TitleBlock,
  SmallRightWrapper,
  SolutionHeadding,
  NoteSup,
  Title,
  SubTitle,
  RightWrapperSup,
  Note,
  BannerNote,
  PhotosContainerRight,
  PhotosContainerLeft,
  Br,
  Line,
  ButtonWhite,
  ButtonBlue,
  ButtonBlack,
  SolutionItem,
  Partners,
} from "./components"
import WidthWrapper from "../widthWrapper"

// Assets
/*  eslint-disable */
import BookIcon from "../../images/enterprise/Book.svg"
import ShapIcon from "../../images/enterprise/Shape_logocontent.svg"
import signatureIcon from "../../images/enterprise/signature.svg"
import supportIcon from "../../images/enterprise/support.svg"
import WinzardIcon from "../../images/enterprise/Wizard_icon.svg"
import checkIcon from "../../images/enterprise/check.svg"
import logo from "../../images/logos/logo_without_text.svg"
import bannerImg from "../../images/enterprise/banner.png"
import rightArrow from "../../images/icons/right-arrow-blue.svg"
import topContentImg from "../../images/enterprise/top_content.png"

import ausIcon from "../../images/enterprise/partners/aus.jpg"
import sproutIcon from "../../images/enterprise/partners/sprout-social.png"
import timHortonsIcon from "../../images/enterprise/partners/timhortons.png"
import milyliIcon from "../../images/enterprise/partners/milyli.png"
import mildredIcon from "../../images/enterprise/partners/mildred.svg"

const solutions = [
  {
    icon: WinzardIcon,
    title: "Creative Services",
    features: ["Content organization", "Content migration", "Handbook design"],
  },
  {
    icon: BookIcon,
    title: "Handbook Management",
    features: [
      "One dashboard hosts all handbooks",
      "Invite collaborators",
      "Customize collaborator access rights",
      "Real-time editing and updates",
      "Easily manage multiple handbooks",
    ],
  },
  {
    icon: ShapIcon,
    title: "Seamless Distribution",
    features: [
      "Customize distribution by employee or location",
      "Auto-distribution in real-time",
      "Mobile and tablet friendly",
      "API integration with your HRIS",
    ],
  },
  {
    icon: signatureIcon,
    title: "Automatic Signature Reports",
    features: [
      "Employee signature tracking",
      "Custom signature reports",
      "Signature reminders",
      "Version archive",
    ],
  },
  {
    icon: supportIcon,
    title: "Customer Support",
    features: ["Software training", "Dedicated account manager"],
  },
]

const challenges_row1 = [
  {
    title: "Management",
    decription:
      "Managing a Word Document is hard. Whether it be editing and updating ever-evolving policy, collaborating with colleagues, or managing multiple handbooks (languages, locations, sectors) at once — your current solution isn't serving you.",
  },
  {
    title: "Compliance",
    decription:
      "Most companies have no accurate way of knowing if an employee has reviewed or signed their handbook, and no easy way to quickly update policies to stay compliant.",
  },
  {
    title: "Branding",
    decription:
      "Most handbook solutions are long, bland Word Documents that are void of visual creativity such as brand colours, images, videos and links to external documents.",
  },
]

const challenges_row2 = [
  {
    title: "Distribution",
    decription:
      "In many instances, employee documents are distributed as printed copies, making it difficult for employees and management to find, review and file crucial information.",
  },
  {
    title: "User Experience",
    decription:
      "Printed handbooks lack the ability for employees to find information easily or keep on top of updates, making it more unlikely for them to adhere to policy.",
  },
  {
    title: "Expense",
    decription:
      "Companies spend a large sum to distribute printed handbooks, especially as they require re-printing for every edit made.",
  },
]

const partners = [
  { logo: ausIcon, url: "" },
  { logo: sproutIcon, url: "" },
  { logo: timHortonsIcon, url: "" },
  { logo: milyliIcon, url: "" },
  { logo: mildredIcon, url: "" },
]

const Enterprise = () => (
  <>
    <Header />

    <div className="bg-blue-3">
      <WidthWrapper>
        <Container style={{ paddingTop: "160px" }}>
          <SmallLeftWrapper>
            <Title
              style={{
                color: "white",
                fontSize: "3.0rem",
                fontFamily: "Helvetica",
              }}
            >
              <p>Seamlessly digitize your company handbooks.</p>
            </Title>
            {/* medium and high */}
            <BannerNote className="hidden md:flex">
              Companies are embracing digital transformation, but lack an
              effective solution to seamlessly digitize handbooks in an
              organized and culture-forward way. That&apos;s where we come in.
            </BannerNote>
            <Br />
            <a
              href={config.CALENDLY.ENTERPRISE}
              target="_blank"
              className="no-underline hidden md:flex"
            >
              <ButtonWhite style={{ maxWidth: "185px" }}>
                Book a demo
                <img
                  src={rightArrow}
                  alt=""
                  style={{ marginLeft: "1rem", marginTop: "3px" }}
                />
              </ButtonWhite>
            </a>
          </SmallLeftWrapper>
          <LargeRightWrapper>
            <PhotosContainerRight>
              <img src={bannerImg} alt="handbook intro" />
            </PhotosContainerRight>
          </LargeRightWrapper>

          {/* only on small */}
          <BannerNote className="md:hidden">
            Companies are embracing digital transformation, but lack an
            effective solution to seamlessly digitize handbooks in an organized
            and culture-forward way. That&apos;s where we come in.
          </BannerNote>
        </Container>
      </WidthWrapper>
    </div>

    <WidthWrapper>
      <Container
        style={{ paddingBottom: 0 }}
        className="flex-col-reverse md:flex-row"
      >
        <LeftWrapper>
          <PhotosContainerLeft>
            <img
              src={topContentImg}
              alt="handbook intro"
              className="w-full h-full"
            />
          </PhotosContainerLeft>
        </LeftWrapper>
        <RightWrapperSup>
          <TitleBlock>
            We wanted to understand enterprise, so we started talking to you.
            Lots of you.
          </TitleBlock>
          <Note>
            What did we learn? That companies share common challenges when it
            comes to their handbook solutions.
          </Note>
        </RightWrapperSup>
      </Container>
    </WidthWrapper>

    <WidthWrapper className="text-center sm:text-left">
      <Container style={{ padding: 0 }}>
        <Wrapper>
          <TitleBlock style={{ textAlign: "center", marginTop: "1rem" }}>
            Common Challenges
          </TitleBlock>
        </Wrapper>
      </Container>

      <Container>
        {challenges_row1.map((item, index) => (
          <WrapperSup key={index}>
            <SubTitle>{item.title}</SubTitle>
            <Note style={{ margin: 0 }}>{item.decription}</Note>
          </WrapperSup>
        ))}
      </Container>

      <Container>
        {challenges_row2.map((item, index) => (
          <WrapperSup key={index}>
            <SubTitle>{item.title}</SubTitle>
            <Note style={{ margin: 0 }}>{item.decription}</Note>
          </WrapperSup>
        ))}
      </Container>
    </WidthWrapper>

    <Container style={{ display: "block" }}>
      <Line></Line>
    </Container>

    <WidthWrapper>
      <Container style={{ paddingBottom: 0 }}>
        <Wrapper style={{ maxWidth: "475px", textAlign: "center" }}>
          <TitleBlock style={{ textAlign: "center", marginBottom: "1rem" }}>
            Our solutions were created specifically for companies like you -
            with multiple locations, thousands of employees, and numerous
            versions of handbooks to manage.
          </TitleBlock>
          <Note>
            AirMason makes it fast and easy to update, distribute and track
            online employee handbooks.
          </Note>
        </Wrapper>
      </Container>

      <Container style={{ flexWrap: "wrap", paddingTop: 0 }}>
        {solutions.map((solution, index) => (
          <WrapperMD3 key={index} style={{ lineHeight: 2 }}>
            <SolutionHeadding>
              <img
                style={{ maxHeight: "30px" }}
                src={solution.icon}
                alt={solution.title}
              />
              <SubTitle>{solution.title}</SubTitle>
            </SolutionHeadding>
            {solution.features.map((feature, index) => (
              <SolutionItem key={index}>
                <img
                  style={{ marginTop: "6px" }}
                  src={checkIcon}
                  alt={feature}
                />
                <NoteSup style={{ marginLeft: "3px" }}>{feature}</NoteSup>
              </SolutionItem>
            ))}
          </WrapperMD3>
        ))}
        <WrapperMD3>
          {/* <Link>
              <img src={learnMoreBtn} alt="CTA" />
            </Link> */}
          <a
            href={config.CALENDLY.ENTERPRISE}
            target="_blank"
            className="no-underline"
          >
            <ButtonBlue style={{ maxWidth: "185px" }}>
              Book a demo
              <img
                src={rightArrow}
                alt=""
                style={{ marginLeft: "1rem", marginTop: "3px" }}
              />
            </ButtonBlue>
          </a>
        </WrapperMD3>
      </Container>
    </WidthWrapper>
    <div style={{ backgroundColor: "#fafafa" }}>
      <WidthWrapper style={{ padding: "30px 20px" }}>
        <Container>
          <Wrapper style={{ maxWidth: "515px", textAlign: "center" }}>
            <TitleBlock
              style={{
                maxWidth: "450px",
                margin: "0 auto",
                width: "100%",
                lineHeight: 1.5,
                fontWeight: 500,
              }}
            >
              Join innovative HR leaders around the world using
              <b> AirMason handbooks.</b>
            </TitleBlock>
            <Note style={{ margin: "36px auto", maxWidth: "360px" }}>
              AirMason serves both small and large enterprise companies with up
              to 500,000 employees. We can provide a custom pricing solution to
              fit your company&apos;s needs.
            </Note>
            <Link to="/contact" className="no-underline">
              <ButtonBlue style={{ margin: "0 auto" }}>
                Contact us to learn more
                <img
                  src={rightArrow}
                  alt=""
                  style={{ marginLeft: "1rem", marginTop: "3px" }}
                />
              </ButtonBlue>
            </Link>
          </Wrapper>
        </Container>
        <Container style={{ paddingTop: "0px" }}>
          <Partners>
            {partners.map((partner, index) => (
              <img
                key={index}
                className="mx-3 w-32"
                src={partner.logo}
                alt=""
              />
            ))}
          </Partners>
        </Container>
      </WidthWrapper>
    </div>

    <div style={{ backgroundColor: "#1183ff" }}>
      <WidthWrapper stype={{ maxWidth: "735px" }}>
        <Container
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <LargeLeftWrapper>
            <Imglogo src={logo} alt=""></Imglogo>
            <Title
              style={{ color: "#fff", foneSize: "1.875rem", fontWeight: 500 }}
            >
              We&apos;d love to show you how AirMason can work for you.
            </Title>
          </LargeLeftWrapper>
          <SmallRightWrapper>
            <a
              className="no-underline"
              href={config.CALENDLY.ENTERPRISE}
              target="_blank"
            >
              <ButtonBlack style={{ marginLeft: "auto" }}>
                Book a demo
              </ButtonBlack>
            </a>
          </SmallRightWrapper>
        </Container>
      </WidthWrapper>
    </div>
  </>
)

export default Enterprise
